import React from 'react';
import { Box, Typography } from '@mui/material';
import SvgImage from './SvgImage';
import styles from './styles';
import { Button, Link } from 'gatsby-theme-material-ui';
import Section from '../Section';

const LinkButton = () => {
  return (
    <Link href="/events/" underline="none">
      <Button variant="kitPrimary" sx={{ ...styles.button }} size="large">
        Посмотреть мероприятия
      </Button>
    </Link>
  );
};
export default function EventNotFound() {
  return (
    <Section bg={'event-not-found'}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.message}>
          <Typography
            variant="desktopH1"
            color="kit.text.main"
            sx={styles.text}
            lineHeight={1}
          >
            Такого события не существует
          </Typography>
          <Typography
            variant="desktopP1"
            color={'kit.text.main'}
            mt={3}
            fontFamily={'RF Rostin'}
            letterSpacing={-0.9}
          >
            Похоже, вы попали на страницу события, которое уже прошло. У нас
            есть много других событий, вы точно найдете что-то интересное!
          </Typography>
          <LinkButton />
        </Box>

        <Box sx={styles.image}>
          <SvgImage />
        </Box>
      </Box>
    </Section>
  );
}
