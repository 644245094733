const styles = {
  container: (theme) => ({
    padding: '32px',
    backgroundColor: '#FFF',
    marginBottom: '16px',
    boxShadow: '0px 36px 56px rgba(61, 33, 136, 0.04)',
    borderRadius: '24px',
    display: 'flex',
    gap: 0,
    alignItems: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      padding: '28px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '24px',
      flexDirection: 'column',
    },
  }),
  speakerPhoto: (theme) => ({
    width: '160px !important',
    height: '160px !important',
    borderRadius: '20px',
    boxShadow: 'none',
    flexShrink: 0,

    [theme.breakpoints.down('md')]: {
      borderRadius: '12px',
      width: '104px !important',
      height: '104px !important',
    },
  }),
  image: {
    height: '100%',
  },
  speakerInfo: (theme) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    mt: { xs: 0, lg: 3 },
  }),
  speakerName: (theme) => ({
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 700,
    color: 'kit.text.main',
    lineHeight: '44px',
    letterSpacing: '-0.06em',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      fontSize: '22px',
      lineHeight: '100%',
    },
  }),
  speakerDescription: (theme) => ({
    textAlign: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  speakerJobsContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    fontSize: '16px',
    width: '100%',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '7px',
    },
  }),
  speakerJob: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '16px',
  },
  speakerJobHeader: {
    color: 'kit.link.main',
    fontFamily: 'Inter',
  },
  speakerJobTitle: {
    fontFamily: 'RF Rostin',
    textTransform: 'uppercase',
    fontWeight: '700',
    flex: 1,
    margin: '6px 0',
    color: 'kit.link.main',
  },
  companyLogo: {
    height: '31px',
    width: 'auto',
  },
  arrowBlock: (theme) => ({
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'start',
    },
  }),
  arrow: (theme) => ({
    alignSelf: 'start',
    width: '58px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  arrowDown: (theme) => ({
    marginLeft: '4px',
    marginTop: '-10px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
};

export default styles;
